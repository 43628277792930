import * as React from "react";
import { Admin, Resource, Button } from "react-admin";
import { authProvider, dataProvider } from "./provider/index";
import { PlayersList, PlayersEdit, PlayersCreate } from "./data/players";
import {
  FootballersCreate,
  FootballersEdit,
  FootballerShowStats,
  FootballersList,
} from "./data/Footballers";
import SportsSoccer from "@material-ui/icons/SportsSoccer";
import PostAdd from "@material-ui/icons/PostAdd";
import NotFound from "./page/notfound";
import "./App.css";
import { createTheme } from "@material-ui/core/styles";
import { Menu } from "./custom/menu";
import Dashboard from "./page/dashbord";
import { PostCreate, PostEdit, PostList, PostShow } from "./data/Post";
import CalendarToday from "@material-ui/icons/CalendarToday";
import GradeIcon from "@material-ui/icons/Grade";
import { createBrowserHistory as createHistory } from "history";
import EventNoteIcon from "@material-ui/icons/EventNote";
import SchedulePage from "./page/scedule";
import NextTrainingPage from "./page/NextTrainingPage";
import {
  SheduleCreate,
  SheduleEdit,
  SheduleList,
  SheduleShow,
} from "./data/Shedule";
const theme = createTheme({
  palette: {
    primary: {
      light: "#82ccdd",
      main: "#60a3bc",
      dark: "#0a3d62",
    },
    secondary: {
      light: "#6a89cc",
      main: "#4a69bd",
      dark: "#0c2461",
    },
    // Switching the dark mode on is a single property value change.
  },
});

const history = createHistory();

const App = () => (
  <Admin
    history={history}
    dashboard={Dashboard}
    menu={Menu}
    theme={theme}
    authProvider={authProvider}
    dataProvider={dataProvider}
    catchAll={NotFound}
  > 

    <Resource
      options={{
        label: "Футболисты",
      }}
      icon={SportsSoccer}
      name="footballers"
      list={FootballersList}
      create={FootballersCreate}
      edit={FootballersEdit}
      show={FootballerShowStats}
    />
    <Resource
      options={{
        label: "Новости",
      }}
      icon={PostAdd}
      name="posts"
      list={PostList}
      create={PostCreate}
      edit={PostEdit}
      show={PostShow}
    />
    <Resource
      options={{
        label: "Расписание",
      }}
      icon={CalendarToday}
      name="scheduleas"
      list={SheduleList}
      create={SheduleCreate}
      edit={SheduleEdit}
      show={SheduleShow}
    />
    <Resource
      options={{
        label: "Достижения",
      }}
      icon={GradeIcon}
      name="players"
      list={PlayersList}
      create={PlayersCreate}
      edit={PlayersEdit}
    />
    <Resource
      options={{
        label: "Ближ. трен.",
      }}
      icon={EventNoteIcon}
      name="training"
      list={NextTrainingPage}
    />
    <Resource
      options={{
        label: "Услуги",
      }}
      icon={EventNoteIcon}
      name="shop"
      list={NextTrainingPage}
    />
  </Admin>
);

export default App;
