import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  TextInput,
  TabbedForm,
  FormTab,
  DateInput,
  Create,
  Show,
  ImageField,
  ImageInput,
  SimpleShowLayout,
  Edit,
} from "react-admin";
import { PlayerAvatar } from "../custom";
import {
  ListActions,
  PostBulkActionButtons,
  ShowPost,
  EditPosts,
} from "../custom/actions";

export const PostList = (props) => (
  <List
    {...props}
    actions={<ListActions />}
    bulkActionButtons={<PostBulkActionButtons />}
  >
    <Datagrid expand={<PostShow />}>
      <TextField label="Название Новости" source="Post_Title" />
      <TextField label="Имя" source="Post_author" />
      <TextField label="Фамилия" source="Post_author_lastname" />
      <DateField label="Дата Создания" source="Post_Date" />
      <EditPosts label="Редактировать" />
      <ShowPost label="Просмотр" />
    </Datagrid>
  </List>
);

export const PostCreate = (props) => (
  <Create {...props}>
    <TabbedForm contentClassName="tabs=form-footballers">
      <FormTab
        label="Новости"
        contentClassName="tabs=form-footballers tabs=form-footballers1"
      >
        <TextInput label="Название" source="Post_Title" />
        <TextInput label="Имя Автора" source="Post_author" />
        <TextInput label="Фамилия Автора" source="Post_author_lastname" />
        <DateInput label="Дата" source="Post_Date" />
      </FormTab>
      <FormTab
        label="Наполнение"
        contentClassName="tabs=form-footballers tabs=form-footballers1"
      >
        <TextInput label="Описание" source="Post_teaser" />
        <ImageInput
          label="Фотографии Событий"
          multiple="true"
          labelSingle="Фотографии Событий"
          source="Post_image"
          accept="image/*"
          placeholder={<p>Оставьте свой файл здесь</p>}
        >
          <ImageField source="Post_image" title="title" />
        </ImageInput>
        <TextInput label="Текст" source="Post_text" />
        <TextInput label="Ссылка на видео" source="Post_video" />
      </FormTab>
    </TabbedForm>
  </Create>
);

export const PostEdit = (props) => (
  <Edit {...props}>
    <TabbedForm contentClassName="tabs=form-footballers">
      <FormTab
        label="Новости"
        contentClassName="tabs=form-footballers tabs=form-footballers1"
      >
        <TextInput label="Название" source="Post_Title" />
        <TextInput label="Имя" source="Post_author" />
        <TextInput label="Фамилия Автора" source="Post_author_lastname" />
        <DateInput label="Дата" source="Post_Date" />
      </FormTab>
      <FormTab
        label="Наполнение"
        contentClassName="tabs=form-footballers tabs=form-footballers1"
      >
        <TextInput label="Описание" source="Post_teaser" />
        <ImageInput
          label="Фотографии Событий"
          multiple="true"
          labelSingle="Фотографии Событий"
          source="Post_image"
          accept="image/*"
          placeholder={<p>Оставьте свой файл здесь</p>}
        >
          <ImageField source="Post_image" title="title" />
        </ImageInput>
        <TextInput label="Текст" source="Post_text" />
        <TextInput label="Ссылка на видео" source="Post_video" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const PostShow = (props) => (
  <Show {...props} title="Новость">
    <SimpleShowLayout className="container-show">
      <TextField label="Название Новости" source="Post_Title" />
      <TextField label="Автор" source="Post_author" />
      <TextField label="Фамилия" source="Post_author_lastname" />
      <DateField label="Дата Создания" source="Post_Date" />
      <TextField label="Описание" source="Post_teaser" />
      <PlayerAvatar source="Post_image" title="title" />
      <TextField label="Текст" source="Post_text" />
      <TextField label="Ссылка на видео" source="Post_video" />
    </SimpleShowLayout>
  </Show>
);

export default { PostList, PostCreate, PostEdit, PostShow };
