import { useRecordContext, Loading } from "react-admin";
import React, { useEffect } from "react";
import { Chart } from "react-google-charts";
import { useStatistics } from "./hooks";

const CustomChartAr = ({ source, name }) => {
  const { get, set } = useStatistics();
  const { Statistics } = useRecordContext();
  const l_name = useRecordContext(name).lastname;
  const f_name = useRecordContext(name).firstname;

  useEffect(() => {
    if (typeof Statistics == "object" && typeof Statistics[0] != "number") {
      set(Statistics);
    }
    return () => {};
  }, [Statistics]);

  return (
    <div>
      <ul>
        <h1
          syle={{
            textAlign: "center",
            position: "absolute",
            width: "100%",
            top: "50%",
          }}
        >
          Статистика
        </h1>
        <h2>
          {l_name} {f_name}
        </h2>
      </ul>
      <ul
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {" "}
        <Chart
          width={"500px"}
          height={"350px"}
          chartType="LineChart"
          loader={<Loading />}
          data={[
            [
              { type: "date", label: "Дата", id: "Date" },
              { type: "number", label: "Удар", id: "Won/Loss" },
            ],
          ].concat(get()?.HitList || [])}
          options={{
            chart: {
              title: "Удар",
              subtitle: "Оценка",
            },
          }}
          rootProps={{ "data-testid": "2" }}
        />
        <Chart
          width={"500px"}
          height={"350px"}
          chartType="LineChart"
          loader={<Loading />}
          data={[
            [
              { type: "date", label: "Дата", id: "Date" },
              { type: "number", label: "Скорость", id: "Won/Loss" },
            ],
          ].concat(get()?.SpeedList || [])}
          options={{
            chart: {
              title: "Скорость",
              subtitle: "Оценка",
              chartArea: { width: "50%" },
              colors: ["#b0120a", "#ffab91"],
            },
          }}
          rootProps={{ "data-testid": "2" }}
        />
        <Chart
          width={"500px"}
          height={"350px"}
          chartType="LineChart"
          loader={<Loading />}
          data={[
            [
              { type: "date", label: "Дата", id: "Date" },
              { type: "number", label: "Прыжок", id: "Won/Loss" },
            ],
          ].concat(get()?.JumpList || [])}
          options={{
            chart: {
              title: "Прыжок",
              subtitle: "Оценка",
              chartArea: { width: "50%" },
            },
          }}
          rootProps={{ "data-testid": "2" }}
        />
        <Chart
          width={"500px"}
          height={"350px"}
          chartType="LineChart"
          loader={<Loading />}
          data={[
            [
              { type: "date", label: "Дата", id: "Date" },
              { type: "number", label: "Реакция", id: "Won/Loss" },
            ],
          ].concat(get()?.ReactionList || [])}
          options={{
            chart: {
              title: "Реакция",
              subtitle: "Оценка",
              chartArea: { width: "50%" },
            },
          }}
          rootProps={{ "data-testid": "2" }}
        />
      </ul>
    </div>
  );
};

export default CustomChartAr;
