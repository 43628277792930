import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title } from "react-admin";
/* import list_players from "./players.json"; */
import { PlayerCard } from "../components";

export default () => {
  //get data about player
  const [Player, setPlayer] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  // const [isError, setIsError] = React.useState(false);
  const fetchData = () => {
    fetch("https://cdn.lk-ft.ru/footballers")
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setPlayer(data);
      })
      .catch((error) => {
        setIsLoading(false);
        // setIsError(true);
        console.log(error);
      });
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  var player_show = [];

  //cheking if Stats length less than null
  Player.map((e, i) => player_show.push(e));
  player_show.map((e, i) => {
    if (e.Statistics.length - 1 < 0) {
      e.Statistics.push((e.Statistics.Hit = "00.00"));
    }
  });

  /*  console.log(player_show) */
  return (
    <Card>
      <Title title="Все Игроки" />
      <CardContent
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {
          player_show &&
            player_show.map((e, i) => (
              <PlayerCard
                key={i}
                color={"#65b2c6"}
                id={e.id}
                position={e.playerPosition}
                age={e.birthday}
                team={e.team}
                img={e.avatar}
                idx={i}
                name={e.lastname}
                fname={e.firstname}
                atk={e.Statistics[e.Statistics.length - 1].Hit}
                def={e.Statistics[e.Statistics.length - 1].Reaction}
                sup={e.Statistics[e.Statistics.length - 1].Jump}
                spd={e.Statistics[e.Statistics.length - 1].Speed}
              />
            ))
          /* list_players.players.map((e,i)=><PlayerCard key={i} color={"#65b2c6"} position={e.position} age={e.age} number={e.number} img={e.photo} idx={i} name={e.name} atk={e.attack} def={e.reaction} sup={e.jump} spd={e.speed}/>) */ //try this method
        }
      </CardContent>
    </Card>
  );
};
