import React, {useState,useEffect} from 'react';
import { List, Datagrid, TextField, ImageField,
BooleanField,
DateField,
EmailField,
SelectField,
RichTextField,
NumberField,
Show, 
SimpleShowLayout,
TextInput,
TabbedForm,
FormTab,
NumberInput,
BulkDeleteButton,
ReferenceInput,
required,
ImageInput,
NullableBooleanInput,
DateInput,
SelectInput,
TopToolbar,
ShowActions,
ShowButton,
Edit,
BooleanInput,
SimpleForm,
Button,
Create
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { AsideChag1 } from "../it/asideChag_1";
import { ListActions, PostBulkActionButtons, Show as ShowPlayer, Edit as EditPlayer } from "../custom/actions";
// import ResetViewsButton from "../custom/resetViewAction";
import { JsonField, JsonInput } from "react-admin-json-view";
import { Avatar, Age ,Age_Card , Avatar_Card} from "../custom";
import { PlayersFilter } from "../custom/playersFilter";
import axios from 'axios';
// import card
import { PlayerCard } from '../components';
import Dashbord from '../page/dashbord';


const choices2 = [
  {id:"Панна",name:"Панна"},
  {id:"3 VS 3",name:"3 VS 3"},
  {id:"Пенальти",name:"Пенальти"},
  {id:"Тестирование",name:"Тестироание"},
]
const choices = [
    {id:0,name:"Goalkeeper"},
    {id:1,name:"DefensiveAttackingFullBack"},
    {id:2,name: "DefensiveLibero"},
    {id:3,name: "DefensiveStopper"},
    {id:4,name: "MidfieldBallWinner"},
    {id:5,name:"MidfieldDefensive"},
    {id:6,name:"MidfieldCreative"},
    {id:7,name: "MidfieldBoxToBox"},
    {id:8,name:"WideMidfielder"},
    {id:9,name: "AttackingDribbler"},
    {id:10,name:"AttackingCounterattacker"},
    {id:11,name:"AttackingClinicalFinisher"},
    {id:12,name:"AttackingTargetMan"},
    {id:13,name:"AttackingGoalPoacher"},
    {id:14,name: "AttackingFalseNine"}
 ];

export const PlayersList = (props) => {

  return (
    <List {...props} filters={<PlayersFilter />} actions={<ListActions />}   >
      {/*  bulkActionButtons={<PostBulkActionButtons />} > */}
        <Datagrid>
          {/*expand={<PlayersShowStats />}
            isRowExpandable={row => row.active}  > */}
          {/*<Avatar source="avatar"/> */}
            <TextField label='Имя' source="fullname" />
            <TextField label="Событие" source="last_statements" />
            <DateField label='Дата события' source="age"/>
            <TextField label="Оценка" source="adresse" />
           {/*<TextField source="insta" /> */}
           {/*<EmailField source="email" /> */}
           {/*<BooleanField source="active"/> */}
           {/*<DateField source="age"/>*/}
           {/*<Age source="age" /> */}
           {/*<JsonField
              source="statistics"
              addLabel={true}
              jsonString={false} // Set to true if the value is a string, default: false
              reactJsonOptions={{
                // Props passed to react-json-view
                name: null,
                collapsed: true,
                enableClipboard: false,
                displayDataTypes: false,
              }}
            />*/}{/* 
             <RichTextField label='Комментарий' source="last_statements"/> */}
            {/*<NumberField source="phone"/>
            <NumberField source="home"/>
            <TextField source="adresse" />*/}
            {/*<Avatar source="gallery"/> */}
            {/*<SelectField source="position" choices={choices} /> */}
            <ShowPlayer  />
            <EditPlayer />
        </Datagrid>
    </List>
)
};

/* export const PlayersShowStats = (props) => (
    <Show {...props} title="Players view" >
        <SimpleShowLayout  className="container-show" >
          {/*   <Avatar source="avatar"/> 
            <TextField source="position" />
             <TextField source="fullname" />
            <JsonField
        source="statistics"
        addLabel={true}
        jsonString={false} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />

      {/*<PlayerCard  color={"#65b2c6"} position={"Positions"}  number={15} img={props?.img} idx={"3"} name={"Victor Bal"} def={90} sup={45} stm={23} drb={31} ldp={60} agl={22} spd={99}/>
       <Avatar source="gallery"/> 
        </SimpleShowLayout>
    </Show>
    
); */
export const PlayersCreate = (props) =>{

  const [player, setPlayer] = React.useState([]);
  React.useEffect(() => {
     // fetchDataPlayer();

      const fetchStat = async () =>{
        const res = await axios.get('https://cdn.lk-ft.ru/footballers');
        setPlayer(res.data)
      }

      fetchStat();
  }, []);

  var pl_array = [];
  player.map((e,i) => {
      pl_array.push({id : e.lastname+' '+e.firstname+' '+e.id+' ', name : e.lastname+' '+e.firstname+' '+e.id+' '});
  })

  console.log(pl_array)


  return(
      <Create {...props}>

      <TabbedForm contentClassName="tabs-form">
              <FormTab label="События" contentClassName="tab-form tab-form-1 "> 

              <SelectInput label="Игрок" source="fullname" choices={pl_array} resettable/>
              <DateInput label="Дата События" source="age"/>
              <SelectInput label="Событие" source="last_statements" choices={choices2} resettable/>
              <TextInput  label="Забито"  source="phone" resettable/>
              <TextInput label="Пропущено" source="insta" resettable/>
              <TextInput label="Оценка" source="adresse" />
              {/* <TextInput disabled label="Id" source="id" /> */}
              
         {/*  <ReferenceInput label="User" source="userId" reference="users" >
              <SelectInput optionText="name" />
          </ReferenceInput> */}
          {/* <ImageInput labelSingle="avatar" source="avatar" accept="image/*"  placeholder={<p>Drop your file here</p>}>
              <ImageField source="avatar" title="title" />
          </ImageInput>
          <TextInput source="fullname" />
           */}
         {/*  <NumberInput source="phone"/>
    <NumberInput source="home"/>
    <TextInput source="adresse" />
          <TextInput  label="Email Address" type="email" source="email" resettable/>
          <TextInput label="social accounts" source="insta" resettable/>
            </FormTab>
            <FormTab label="details" contentClassName="tab-form tab-form-2 ">
          <SelectInput label="position" source="position" choices={choices} resettable/>
          <NullableBooleanInput label="active player" source="active" />
          <JsonInput
      source="statistics"
      addLabel={true}
      jsonString={false} // Set to true if the value is a string, default: false
      reactJsonOptions={{
        // Props passed to react-json-view
        name: null,
        collapsed: true,
        enableClipboard: false,
        displayDataTypes: false,
      }}
    />
    <TextInput multiline source="last_statements"/>
    
    <ImageInput multiple labelMultiple="gallery" source="gallery" accept="image/*"  placeholder={<p>Drop your files here</p>}>
           <ImageField source="gallery" title="title" />
      </ImageInput> */}
       </FormTab> 
      </TabbedForm>
      </Create>
    );
}

     // PlayersShow
export const PlayersShow = (props) => (
    <Show {...props} title="Players view" >
        <SimpleShowLayout>
        {/* <Avatar source="avatar"/> */}
            <TextField source="position" />
             <TextField source="fullname" />
            <TextField source="insta" />
            <EmailField source="email" />
            <BooleanField source="active"/>
            <DateField label="birth date" source="age"/>
            <JsonField
        source="statistics"
        addLabel={true}
        jsonString={false} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <RichTextField source="last_statements"/>
      <NumberField source="phone"/>
      <NumberField source="home"/>
      <TextField source="adresse" />
      <Avatar source="gallery"/>

        </SimpleShowLayout>
        
    </Show>
);


export const PlayersEdit = (props) => {
  const [player, setPlayer] = React.useState([]);
  React.useEffect(() => {
     // fetchDataPlayer();

      const fetchStat = async () =>{
        const res = await axios.get('https://cdn.lk-ft.ru/footballers');
        setPlayer(res.data)
      }

      fetchStat();
  }, []);

  var pl_array = [];
  player.map((e,i) => {
      pl_array.push({id :e.lastname+' '+e.firstname+' '+e.id+' ', name : e.lastname+' '+e.firstname+' '+e.id+' '});
  })

  console.log(pl_array)
  return(
    <Edit  {...props} >
    <TabbedForm contentClassName="tabs-form">
               <FormTab label="События" contentClassName="tab-form tab-form-1 "> 

              <SelectInput label="Игрок" source="fullname" choices={pl_array} resettable/>
              <DateInput label="Дата События" source="age"/>
              <SelectInput label="Событие" source="last_statements" choices={choices2} resettable/>
              <TextInput  label="Забито"  source="phone" resettable/>
              <TextInput label="Пропущено" source="insta" resettable/>
              <TextInput label="Оценка" source="adresse" />
              {/* <TextInput disabled label="Id" source="id" /> */}
              
         {/*  <ReferenceInput label="User" source="userId" reference="users" >
              <SelectInput optionText="name" />
          </ReferenceInput> */}
          {/* <ImageInput labelSingle="avatar" source="avatar" accept="image/*"  placeholder={<p>Drop your file here</p>}>
              <ImageField source="avatar" title="title" />
          </ImageInput>
          <TextInput source="fullname" />
           */}
         {/*  <NumberInput source="phone"/>
    <NumberInput source="home"/>
    <TextInput source="adresse" />
          <TextInput  label="Email Address" type="email" source="email" resettable/>
          <TextInput label="social accounts" source="insta" resettable/>
            </FormTab>
            <FormTab label="details" contentClassName="tab-form tab-form-2 ">
          <SelectInput label="position" source="position" choices={choices} resettable/>
          <NullableBooleanInput label="active player" source="active" />
          <JsonInput
      source="statistics"
      addLabel={true}
      jsonString={false} // Set to true if the value is a string, default: false
      reactJsonOptions={{
        // Props passed to react-json-view
        name: null,
        collapsed: true,
        enableClipboard: false,
        displayDataTypes: false,
      }}
    />
    <TextInput multiline source="last_statements"/>
    
    <ImageInput multiple labelMultiple="gallery" source="gallery" accept="image/*"  placeholder={<p>Drop your files here</p>}>
           <ImageField source="gallery" title="title" />
      </ImageInput> */}
       </FormTab> 
      </TabbedForm>
</Edit>
);}


export default { PlayersList,PlayersShow , PlayersEdit, PlayersCreate };