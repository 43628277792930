import * as React from 'react';
import { cloneElement, Fragment } from 'react';
import {
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    ShowButton,
    Button,
    sanitizeListRestProps,
    EditButton,
    
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    BulkDeleteWithUndoButton,
} from 'react-admin';
import { VisibilityOff } from '@material-ui/icons';

const ResetViewsButton = ({ selectedIds }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, { loading }] = useUpdateMany(
        'posts',
        selectedIds,
        { views: 0 },
        {
            onSuccess: () => {
                refresh();
                notify('Posts updated');
                unselectAll('posts');
            },
            onFailure: error => notify('Error: posts not updated', 'warning'),
        }
    );

    return (
        <Button
            label="resetViews"
            disabled={loading}
            onClick={updateMany}
        >
            <VisibilityOff />
        </Button>
    );
};

export const PostBulkActionButtons = props => { 
    const { basePath, data, resource } = props;
    return (
    <Fragment>
        <ResetViewsButton  {...props} />
        <ShowButton basePath={basePath} record={data} />
        <EditButton  basePath={basePath} record={data} />
        <BulkDeleteWithUndoButton {...props} />
    </Fragment>
)
};


export const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
            
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
        </TopToolbar>
    );
};



export const Edit = ({ record }) => (
    <EditButton basePath="/players" label="Редактировать"  record={record} />
);

export const EditFootballers = ({ record }) => (
    <EditButton basePath="/Footballers" label="Редактировать" record={record} />
);
export const EditPosts = ({ record }) => (
    <EditButton basePath="/Posts" label="Редактировать"  record={record} />
);

export const EditShedule = ({ record }) => (
    <EditButton basePath="/scheduleas" label="Редактировать"  record={record} />
);

export const Show = ({ record }) => (
    <ShowButton basePath="/players" label="Просмотр" record={record} />
);

export const ShowFootballers = ({ record }) => (
    <ShowButton basePath="/Footballers" label="График" record={record} />
);

export const ShowPost = ({ record }) => (
    <ShowButton basePath="/Posts" label="Просмотр" record={record} />  
);


export const ShowShedule = ({ record }) => (
    <ShowButton basePath="/scheduleas" label="Просмотр" record={record} />  
);

export default { ListActions, PostBulkActionButtons, Edit, Show , ShowPost ,EditPosts}