import React from "react";
import { BooleanInput } from "react-admin";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityIconOff from "@material-ui/icons/VisibilityOff";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import axios from "axios";

const choices = [
  { id: "Вратарь", name: "Вратарь" },
  { id: "Защитник(ЦЗ)", name: "Защитник(ЦЗ)" },
  { id: "Крайний защитник", name: "Крайний защитник (Лз,Пз)" },
  { id: "Центральный ПЗ", name: "Центральный Полузащитник" },
  { id: "Атакующий ПЗ", name: "Атакующий Полузащитник" },
  { id: "Опорный ПЗ", name: "Опорный Полузащитник" },
  { id: "Фланговый ПЗ", name: "Фланговый Полузащитник (ПП,ЛП)" },
  { id: "Нападающий", name: "Нападающий" },
  { id: "Ф. Нападающий", name: "Фланговый Нападающий" },
];

const choices_leg = [
  { id: "Правая", name: "Правая" },
  { id: "Левая", name: "Левая" },
];

const choicesLessons = [
  { id: "1 занятие", name: "1 занятие" },
  { id: "8 занятий", name: "8 занятий" },
  { id: "1 месяц", name: "1 месяц" },
  { id: "2 месяца", name: "2 месяца" },
  { id: "3 месяца", name: "3 месяца" },
  { id: "6 месяцев", name: "6 месяцев" },
  { id: " - ", name: " - " },
];

export const ChangeMainInfo = ({ id }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [footballPlayer, setFootballPlayer] = React.useState({});
  const [showPassword, setShowPassword] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [file, setFile] = React.useState(false);
  const [avatar, setAvatar] = React.useState();
  const [playerPoints, setPlayerPoints] = React.useState();
  const url = "https://admin.lk-ft.ru/footskill/api/v1/points/" + id + "/";

  const fetchPoints = () => {
    fetch(url)
      .then((res) => setPlayerPoints(res.body.count_of_point))
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchData = () => {
    fetch("https://cdn.lk-ft.ru/footballers/" + id)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setFootballPlayer(data);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
        console.log(error);
      });
    fetch(url)
      .then((res) => res.json())
      .then( (res) => setPlayerPoints(res.body.count_of_point))
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    fetchData();
    // fetchPoints();
  }, []);

  const handleClickPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (prop) => (event) => {
    setFootballPlayer({ ...footballPlayer, [prop]: event.target.value });
  };

  const saveData = () => {
    axios.get(url + playerPoints + "/").then((res) => console.log(res));
    axios
      .put("https://cdn.lk-ft.ru/footballers/" + id, footballPlayer)
      .then((res) => {
        setTimeout(alert("Изменения сохранены"), 800);
      })
      .catch((error) => {
        console.log("err:", error);
      });
  };

  const upload = () => {
    axios
      .put("https://cdn.lk-ft.ru/footballers/" + id, footballPlayer)
      .then((res) => {
        alert('Фотография загружена, нажмите кнопку "Cохранить"');
      })
      .catch((error) => {
        console.log("err:", error);
      });
  };

  const handleChangeFile = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
  };

  const uploadAvatar = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("files", file);
    fetch("https://cdn.lk-ft.ru/upload/", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setAvatar(data[0]);
      })
      .then(() => {
        setFootballPlayer((s) => ({ ...s, avatar: avatar }));
      })
      .then(() => {
        upload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (avatar && footballPlayer && JSON.stringify(footballPlayer) != "{}") {
      setFootballPlayer((s) => ({ ...s, avatar: avatar }));
    }
  }, []);

  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  return (
    <>
      {footballPlayer ? (
        <div style={{ width: "100%" }}>
          <div
            style={{
              height: "150px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              margin: "10px 0px",
            }}
          >
            <label>Изменить фотографию</label>
            <Input type="file"  accept="image/*" onChange={handleChangeFile} />
         
            <Button
               style={{
                borderColor: "blue",
                marginRight: "15px",
                color: "blue",
              }}
              type="submit"
              variant="outlined"
              onClick={uploadAvatar}
            >
              Загрузить фотографию
            </Button>
          </div>
          <TextField
            style={{ width: "100%", margin: "10px 0px" }}
            variant="outlined"
            label="Номер телефона"
            // type='text'
            onChange={handleChange("f_email")}
            focused={footballPlayer?.f_email ? true : false}
            maxRows={1}
            value={footballPlayer?.f_email}
          />
          <TextField
            style={{ width: "100%", margin: "10px 0px" }}
            variant="outlined"
            label="Пароль"
            type={showPassword ? "text" : "password"}
            focused={footballPlayer?.f_password ? true : false}
            maxRows={1}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button onClick={handleClickPassword}>
                    {showPassword ? <VisibilityIconOff /> : <VisibilityIcon />}
                  </Button>
                </InputAdornment>
              ),
            }}
            onChange={handleChange("f_password")}
            value={footballPlayer.f_password}
          />
          <TextField
            style={{ width: "100%", margin: "10px 0px" }}
            variant="outlined"
            label="Имя"
            focused={footballPlayer?.firstname ? true : false}
            onChange={handleChange("firstname")}
            maxRows={1}
            value={footballPlayer?.firstname}
          />
          <TextField
            style={{ width: "100%", margin: "10px 0px" }}
            variant="outlined"
            label="Фамилия"
            onChange={handleChange("lastname")}
            focused={footballPlayer?.lastname ? true : false}
            maxRows={1}
            value={footballPlayer?.lastname}
          />
          <TextField
            style={{ width: "100%", margin: "10px 0px" }}
            variant="outlined"
            label="Команда"
            focused={footballPlayer?.team ? true : false}

            onChange={handleChange("team")}
            maxRows={1}
            value={footballPlayer?.team}
          />
          <div>Дата рождения</div>
          <input
            style={{
              width: "100%",
              height: "50px",
              margin: "10px 0px",
              borderRadius: "5px",
              borderColor: "grey",
            }}
            type="Date"
            name="Дата"
            value={footballPlayer?.birthday}
            onChange={handleChange("birthday")}
          />
          <BooleanInput label="Активный Игрок" source="Active" />
          <TextField
            style={{ width: "100%", margin: "10px 0px" }}
            variant="outlined"
            label={footballPlayer?.playerPosition}
            onChange={handleChange("playerPosition")}
            value={footballPlayer?.playerPosition}
            select
            helperText="Позиция игрока"
          >
            {choices.map((option, i) => (
              <MenuItem key={i} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            style={{ width: "100%", margin: "10px 0px" }}
            variant="outlined"
            label={footballPlayer?.lead_leg}
            onChange={handleChange("lead_leg")}
            value={footballPlayer?.lead_leg}
            helperText="Ведущая нога"
            select
          >
            {choices_leg.map((option, i) => (
              <MenuItem key={i} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            style={{ width: "100%", margin: "10px 0px" }}
            variant="outlined"
            label="Баллы"
            placeholder="Баллы"
            focused={playerPoints ? true : false}
            // type='text'
            onChange={(e) => {
              setPlayerPoints(Number(e.currentTarget.value));
            }}
            maxRows={1}
            value={playerPoints}
          />
        </div>
      ) : (
        <></>
      )}
      <Button
        type="button"
        variant="outlined"
        color="warning"
        style={{
          borderColor: "blue",
          marginRight: "15px",
          color: "blue",
        }}
        onClick={saveData}
      >
        Сохранить
      </Button>
    </>
  );
};

export const ChangeSecondaryInfo = ({ id }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [footballPlayer, setFootballPlayer] = React.useState({});
  const [showPassword, setShowPassword] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const fetchData = () => {
    fetch("https://cdn.lk-ft.ru/footballers/" + id)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setFootballPlayer(data);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
        console.log(error);
      });
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  const handleClickPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (prop) => (event) => {
    setFootballPlayer({ ...footballPlayer, [prop]: event.target.value });
  };

  const saveData = () => {
    axios
      .put("https://cdn.lk-ft.ru/footballers/" + id, footballPlayer)
      .then((res) => {
        setTimeout(alert("Изменения сохранены"), 800);
      })
      .catch((error) => {
        console.log("err:", error);
      });
  };
  return (
    <>
      {footballPlayer ? (
        <div style={{ width: "100%", margin: "10px 0px" }}>
          {/* <TextField
            style={{ width: "100%" }}
            variant="outlined"
            label="Количество посещенных лагерей"
            onChange={handleChange("count_of_camps")}
            maxRows={1}
            value={footballPlayer?.count_of_camps}
          />
          <TextField
            style={{ width: "100%", margin: "10px 0px" }}
            variant="outlined"
            label="Количество посещенных тренировок"
            // type='text'
            onChange={handleChange("count_of_training")}
            maxRows={1}
            value={footballPlayer?.count_of_training}
          />
          <TextField
            style={{ width: "100%", margin: "10px 0px" }}
            variant="outlined"
            label="Количество потраченных баллов"
            onChange={handleChange("count_of_minus_points")}
            maxRows={1}
            value={footballPlayer?.count_of_minus_points}
          /> */}
          <div>Дата последней оплаты</div>
          <input
            style={{
              width: "100%",
              height: "50px",
              margin: "10px 0px",
              borderRadius: "5px",
              borderColor: "grey",
            }}
            type="Date"
            name="Дата"
            value={footballPlayer?.date_of_last_pay}
            onChange={handleChange("date_of_last_pay")}
            label="Дата последней оплаты"
          />
          <TextField
            style={{ width: "100%", margin: "10px 0px" }}
            variant="outlined"
            label={footballPlayer?.number_of_lessons}
            onChange={handleChange("number_of_lessons")}
            value={footballPlayer?.number_of_lessons}
            helperText="Занятия/Месяцы"
            select
          >
            {choicesLessons.map((option, i) => (
              <MenuItem key={i} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            style={{ width: "100%", margin: "10px 0px" }}
            variant="outlined"
            label="Вариант абонемента"
            onChange={handleChange("variant_of_subscription")}
            maxRows={1}
            value={footballPlayer?.variant_of_subscription}
          />
        </div>
      ) : (
        <></>
      )}
      <Button
        type="button"
        variant="outlined"
        color="warning"
        style={{
          borderColor: "blue",
          marginRight: "15px",
          color: "blue",
        }}
        // disabled={disabled}
        onClick={saveData}
      >
        Сохранить
      </Button>
    </>
  );
};
