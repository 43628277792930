import React, { useState } from "react";

export default function (props) {
  //for show little circle on graf

  // get age by date of birthday
  var today = new Date();
  var birthDate = new Date(props?.age);
  var ages = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    ages--;
  }

  var attack_stat = props?.atk;
  var reaction_stat = props?.def;
  var jump_stat = props?.sup;
  var speed_stat = props?.spd;

  //get url for show avatar
  var imagess = props?.img;
  if (imagess === null)
    imagess =
      "https://cdn.lk-ft.ru/uploads/small_png_transparent_cartoon_drawing_whatsapp_boys_comics_face_head_653814e7e6.png?6459691.3";
  else imagess = "https://cdn.lk-ft.ru" + props?.img.url;

  // position
  var player_poss = props?.position;

  //show player name
  var full_name = props?.name + " " + props?.fname[0];

  const defaults = {
    color: "#EB3599",
  };
  const [color, setcolor] = useState(props?.color || defaults.color);
  const [flipped, setflipped] = useState(false);
  const goFullStats = () => {
    //console.log("full stats:", Math.random());
    //console.log("  age :", ages , " img: ",  imagess);
  };
  return (
    <div
      className="playercard"
      onMouseEnter={() => setflipped(!flipped)}
      onMouseLeave={() => setflipped(!flipped)}
    >
      <div className={flipped ? "back_playercard" : "front_playercard"}>
        <svg
          //onMouseEnter={()=>setcolor(defaults.color)}
          //onMouseLeave={()=>setcolor(props?.color || defaults.color)}
          width={242}
          height={407}
          style={{
            marginBottom: "1em",
          }}
          viewBox="0 0 242 407"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          // {...props}
        >
          <path
            d="M9 167l89.65 18.709a122.996 122.996 0 0050.682-.089L237 167v206l-113 26L9 373V167z"
            fill="#fff"
          />
          <g filter="url(#prefix__filter0_f)">
            <path
              d="M131.754 61L9 99.095V108l144-40.568L131.754 61z"
              fill="url(#prefix__paint0_linear)"
            />
          </g>
          <g filter="url(#prefix__filter_img)">
            <rect
              x={57}
              y={40}
              width={129}
              height={127}
              rx={41}
              fill={`url(#prefix__pattern${props?.idx | 0})`}
            />
          </g>
          <g filter="url(#prefix__filter1_f)">
            <path
              d="M191 25L9 63.5v9l213.5-41L191 25z"
              fill="url(#prefix__paint1_linear)"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M122.608.379a7 7 0 00-3.216.001L5.889 27.226A7 7 0 00.5 34.038v338.924a7 7 0 005.389 6.813l113.503 26.845c1.058.25 2.159.25 3.216.001l113.997-26.85a7 7 0 005.395-6.814V34.043a7 7 0 00-5.395-6.814L122.608.38zM12.5 48.239a7 7 0 015.627-6.864l127.835-25.567a7 7 0 013.061.07l77.665 19.302A7 7 0 01232 41.973v323.428a7 7 0 01-5.462 6.829l-103.982 23.42a7.006 7.006 0 01-3.11-.008L17.928 372.251a7 7 0 01-5.428-6.822V48.239z"
            fill={color}
          />
          {/* <text x={props?.number ? Number(props?.number) >= 10 ? 170 : 190 : 170} y={220} class="player_number" fill={color}>{props?.number}</text> */}
          <rect x={20} y={196} width={124} height={34} rx={15} fill={color} />
          <text
            style={{
              fontSize: `${19 - full_name.split("").length / 2.7}px`,
            }}
            className="player_name"
            fill="#ffffff"
            x="30"
            y="219"
          >
            {full_name}
          </text>
          <text
            x={57}
            y={250}
            alignmentBaseline="central"
            dx={
              props?.team
                ? props?.team.split("").length < 10
                  ? 12
                  : props?.team.split("").length < 14
                  ? -4
                  : -30
                : 0
            }
            className="player_position_age"
            fill={color}
          >
            {props?.team}
          </text>
          <text
            x={57}
            y={300}
            alignmentBaseline="central"
            dx={37}
            className="player_position_age"
            fill={color}
          >
            {ages} лет
          </text>
          <text
            x={20}
            y={350}
            className="player_position_age"
            dx={player_poss ? (player_poss.split("").length < 13 ? 30 : 5) : 25}
            fill={color}
          >
            {player_poss || "------------"}
          </text>
          <defs>
            <linearGradient
              id="prefix__paint0_linear"
              x1={81}
              y1={61}
              x2={81}
              y2={108}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0.182} stopColor="#E5E5E5" stopOpacity={0} />
              <stop offset={0.406} stopColor={color} />
              <stop offset={0.995} stopColor={color} />
            </linearGradient>
            <linearGradient
              id="prefix__paint1_linear"
              x1={115.75}
              y1={25}
              x2={115.75}
              y2={72.5}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0.182} stopColor="#E5E5E5" stopOpacity={0} />
              <stop offset={0.406} stopColor={color} />
              <stop offset={0.995} stopColor={color} />
            </linearGradient>
            <filter
              id="prefix__filter0_f"
              x={5}
              y={57}
              width={152}
              height={55}
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feBlend
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation={2}
                result="effect1_foregroundBlur"
              />
            </filter>
            <filter
              id="prefix__filter1_f"
              x={5}
              y={21}
              width={221.5}
              height={55.5}
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="0.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
            <filter
              id="prefix__filter_img"
              x="56"
              y="39"
              width="131"
              height="129"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="0.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
            <pattern
              id={`prefix__pattern${props?.idx | 0}`}
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
            >
              <use
                xlinkHref={`#prefix__image${props?.idx | 0}`}
                transform="matrix(.00667 0 0 .00677 0 -.008)"
              />
            </pattern>
            <image
              id={`prefix__image${props?.idx | 0}`}
              width={150}
              height={150}
              xlinkHref={imagess}
            />
          </defs>
        </svg>
      </div>
      <div className={!flipped ? "back_playercard" : "front_playercard"}>
        <svg
          width={242}
          height={407}
          viewBox="0 0 242 407"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          // {...props}
        >
          <path
            d="M8.5 38l86.321-22.518a123 123 0 0157.618-1.08L236.5 33v340l-113 26-115-26V38z"
            fill="#fff"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M122.108.379a7 7 0 00-3.216.001L5.389 27.226A7 7 0 000 34.038v338.924a7 7 0 005.389 6.813l113.503 26.845c1.058.25 2.159.25 3.216.001l113.997-26.85a7 7 0 005.395-6.814V34.043a7 7 0 00-5.395-6.814L122.108.38zM12 48.239a7 7 0 015.627-6.864l127.835-25.567a7 7 0 013.061.07l77.665 19.302a7 7 0 015.312 6.793v323.428a7 7 0 01-5.462 6.829l-103.982 23.42a7.006 7.006 0 01-3.11-.008L17.428 372.251A7 7 0 0112 365.429V48.239z"
            fill={color}
          />
          <mask
            id="prefix__a"
            maskUnits="userSpaceOnUse"
            x={11}
            y={18}
            width={221}
            height={379}
          >
            <path
              d="M12.5 43l103.806-21.936a122.997 122.997 0 0157.037 1.472L231.5 38v333l-108 26-112-26 1-328z"
              fill="#fff"
            />
          </mask>
          <g mask="url(#prefix__a)" stroke="#F0ECEC" strokeWidth={8}>
            <path d="M174.411 147c0 32.69-23.116 58.263-50.559 58.263-27.444 0-50.56-25.573-50.56-58.263s23.116-58.263 50.56-58.263c27.443 0 50.559 25.573 50.559 58.263z" />
            <path d="M188.055 147.394c0 41.178-29.145 73.631-64.026 73.631s-64.027-32.453-64.027-73.631 29.146-73.631 64.027-73.631 64.026 32.453 64.026 73.631z" />
            <path d="M201 147c0 49.666-35.175 89-77.494 89-42.318 0-77.493-39.334-77.493-89s35.175-89 77.493-89C165.825 58 201 97.334 201 147z" />
            <path d="M158.049 147c0 22.244-15.696 39.347-33.985 39.347S90.08 169.244 90.08 147s15.695-39.347 33.984-39.347 33.985 17.103 33.985 39.347z" />
            <path d="M141.668 147c0 12.232-8.583 21.22-18.1 21.22-9.516 0-18.1-8.988-18.1-21.22s8.584-21.22 18.1-21.22c9.517 0 18.1 8.988 18.1 21.22z" />
          </g>

          <svg>
            <g className="stat_name">
              <text x={35} y={140}>
                HIT:
              </text>
              <g>
                <text x={135} y={140} className="show_description">
                  {attack_stat}
                </text>
              </g>
            </g>
            <g className="stat_name">
              <text x={35} y={260}>
                JUMP:
              </text>
              <g>
                <text x={135} y={260} className="show_description">
                  {jump_stat}
                </text>
              </g>
            </g>
            <g className="stat_name">
              <text x={35} y={180}>
                REACTION:
              </text>
              <g>
                <text x={135} y={180} className="show_description">
                  {reaction_stat}
                </text>
              </g>
            </g>
            <g className="stat_name">
              <text x={35} y={220}>
                SPEED:
              </text>
              <g>
                <text x={135} y={220} className="show_description">
                  {speed_stat} km\h
                </text>
              </g>
            </g>
            <g className="score_name">
              <text x={75} y={80}>
                ОЦЕНКА
              </text>
            </g>
          </svg>

          <g
            className="stats-bt"
            filter="url(#prefix__filter0_d)"
            onClick={goFullStats}
            style={{
              cursor: "pointer",
            }}
          >
            <rect
              className="push-btn"
              x={28}
              y={295}
              width={191}
              height={60}
              rx={5}
              fill={color}
            />

            <g className="stats-btn" fill="#fff">
              <text x={52} y={330}>
                <a
                  href={
                    "https://dev.lk-ft.ru/Footballers/" + props?.id + "/show"
                  }
                >
                  СТАТИСТИКА
                </a>
              </text>
            </g>
          </g>
          <defs>
            <filter
              id="prefix__filter0_d"
              x={25}
              y={292}
              width={197}
              height={66}
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation={1.5} />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
}
