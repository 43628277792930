import React , { useState } from "react";
import {
    Typography
 } from "@material-ui/core";
 import { JsonField } from "react-admin-json-view";
export const AsideChag1 = ({selectedIds, data }) =>{
    //    console.log(props);
    const value = (source = null, index = 0) => [
        <Typography variant="body2">
           nothing selected
    </Typography> ,
      <JsonField source={source} />
    ][index];
    console.log(selectedIds, data);
    const [selected,  setSelected] = useState(null);
    selectedIds === [] ? setSelected(value()) : setSelected(value(data[selectedIds], 1))
    return (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="h6">Players details</Typography>
        {selected}
    </div>
);
        }
export default { AsideChag1 };