import React from "react";
import {
  List,
  Datagrid,
  ImageField,
  BooleanField,
  DateField,
  Show,
  TextInput,
  TabbedForm,
  FormTab,
  ImageInput,
  DateInput,
  SelectInput,
  Edit,
  SimpleShowLayout,
  PasswordInput,
  BooleanInput,
  Create,
  TextField,
  Filter,
} from "react-admin";
import {
  ListActions,
  PostBulkActionButtons,
  ShowFootballers as ShowPlayer,
  EditFootballers as EditPlayer,
} from "../custom/actions";
import { PlayerAvatar } from "../custom";
import StatIterator from "../custom/CustomItField";
import CustomChartAr from "../custom/CustomChartAr";
import axios from "axios";
import {
  ChangeMainInfo,
  ChangeSecondaryInfo,
} from "../custom/CustomChangeFootballers";

const choices = [
  { id: "Вратарь", name: "Вратарь" },
  { id: "Защитник(ЦЗ)", name: "Защитник(ЦЗ)" },
  { id: "Крайний защитник", name: "Крайний защитник (Лз,Пз)" },
  { id: "Центральный ПЗ", name: "Центральный Полузащитник" },
  { id: "Атакующий ПЗ", name: "Атакующий Полузащитник" },
  { id: "Опорный ПЗ", name: "Опорный Полузащитник" },
  { id: "Фланговый ПЗ", name: "Фланговый Полузащитник (ПП,ЛП)" },
  { id: "Нападающий", name: "Нападающий" },
  { id: "Ф. Нападающий", name: "Фланговый Нападающий" },
];

const choices_leg = [
  { id: "Правая", name: "Правая" },
  { id: "Левая", name: "Левая" },
];

const choicesLessons = [
  { id: "1 занятие", name: "1 занятие" },
  { id: "8 занятий", name: "8 занятий" },
  { id: "1 месяц", name: "1 месяц" },
  { id: "2 месяца", name: "2 месяца" },
  { id: "3 месяца", name: "3 месяца" },
  { id: "6 месяцев", name: "6 месяцев" },
  { id: " - ", name: " - " },
];

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Фамилия" source="lastname" alwaysOn />
    <TextInput label="Номер" source="f_email" alwaysOn />
  </Filter>
);

export const FootballersList = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [footballPlayer, setFootballPlayer] = React.useState([]);
  const [isError, setIsError] = React.useState(false);

  const fetchData = () => {
    fetch("https://cdn.lk-ft.ru/footballers/")
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setFootballPlayer(data);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
        console.log(error);
      });
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  return (
    <List
      {...props}
      actions={<ListActions />}
      bulkActionButtons={<PostBulkActionButtons />}
      filters={<PostFilter />}
    >
      <Datagrid expand={<FootballerDateOfPay />}>
        <PlayerAvatar
          label="Фотографиия Игрока"
          source="avatar"
          title="title"
        />
        <TextField label="Имя" source="firstname" />
        <TextField label="Фамилия" source="lastname" />
        <TextField label="Команда" source="team" />
        <TextField label="Ведущая Нога" source="lead_leg" />
        <TextField label="Позиция" source="playerPosition" />
        <BooleanField label="Активный" source="Active" />
        <DateField label="Дата Рождения" source="birthday" />
        <EditPlayer />
        <ShowPlayer />
      </Datagrid>
    </List>
  );
};

export const FootballerShowStats = (props) => {
  return (
    <Show {...props} title="Полная Статистика">
      <CustomChartAr source="Statistics" name={props?.lastname} />
    </Show>
  );
};

export const FootballerDateOfPay = (props) => {
  return (
    <Show {...props} title="Последняя Оплата">
      <SimpleShowLayout className="container-show">
        <DateField label="Дата Последней Оплаты" source="date_of_last_pay" />
        <TextField label="Занятия/Месяцы" source="number_of_lessons" />
        <TextField
          label="Вариант Абонемента"
          source="variant_of_subscription"
        />
      </SimpleShowLayout>
    </Show>
  );
};

export const FootballersCreate = (props) => (
  <Create {...props}>
    <TabbedForm contentClassName="tabs=form-footballers">
      <FormTab
        label="Студент"
        contentClassName="tabs=form-footballers tabs=form-footballers1"
      >
        <ImageInput
          label="Фотография Игрока"
          labelSingle="Фотография Игрока"
          source="avatar"
          accept="image/*"
          placeholder={<p>Оставьте свой файл здесь</p>}
        >
          <ImageField source="avatar" title="title" />
        </ImageInput>
        <TextInput label="Почта" source="f_email" />
        <PasswordInput label="Пароль" source="f_password" />
        <TextInput source="firstname" label="Имя" />
        <TextInput source="lastname" label="Фамилия" />
        <TextInput label="Команда" source="team" defaultValue="0" />
        <DateInput source="birthday" label="день рождения" />
        <BooleanInput label="Активный Игрок" source="Active" />
        <SelectInput
          label="Позиция игрока"
          source="playerPosition"
          choices={choices}
        />
        <SelectInput
          label="Ведущая Нога"
          source="lead_leg"
          choices={choices_leg}
        />
      </FormTab>
      <FormTab label="Статистика">
        <StatIterator />
      </FormTab>
      <FormTab label="Дата Оплаты">
        {/* <TextInput source="count_of_camps" label="Количество посещенных лагерей" /> 
                <TextInput label="Колличество посещенных теренеровок" source="count_of_training" />
                <TextInput source='count_of_points' label="Количество накопленых баллов" />
                <TextInput label="Количество потраченых баллов" source='count_of_minus_points' /> */}
        <DateInput
          label="Дата Последней Оплаты"
          source="date_of_last_pay"
          defaultValue="1999-01-01"
        />
        {/*          <TextInput label="Количество потраченых баллов" source='count_of_minus_points' /> 
                <SelectInput label="Занятия/Месяцы" source="number_of_lessons" defaultValue="1 занятие" choices={choicesLessons} />
                <TextInput label="Вариант Абонемента" source="variant_of_subscription" />
                <BooleanInput label="Оплачено за баллы" source="paid_for_points" disabled /> */}
      </FormTab>
    </TabbedForm>
  </Create>
);

export const FootballersEdit = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [footballPlayer, setFootballPlayer] = React.useState({});
  const [showPassword, setShowPassword] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  // console.log(props);

  const fetchData = () => {
    fetch("https://cdn.lk-ft.ru/footballers/" + props.id)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setFootballPlayer(data);
        // lastId = Player[Player.length - 1].id;
        // console.log(footballPlayer);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
        console.log(error);
      });
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  const handleClickPassword = () => {
    setShowPassword(!showPassword);
    // e.preventDefault();
    // console.log(showPassword)
  };

  const handleChange = (prop) => (event) => {
    setFootballPlayer({ ...footballPlayer, [prop]: event.target.value });
    // console.log(footballPlayer);
  };

  const saveData = () => {
    console.log(footballPlayer);
    // setDisabled(true)
    axios
      .put("https://cdn.lk-ft.ru/footballers/" + props.id, footballPlayer)
      .then((res) => {
        // console.log("res:", res);
        setTimeout(alert("Изменения сохранены"), 800);
        // setDisabled(false);
      })
      .catch((error) => {
        console.log("err:", error);
        // setDisabled(false);s
      });
  };

  return (
    <Edit title="Редактировать" {...props}>
      <TabbedForm contentClassName="tabs=form-footballers">
        <FormTab
          label="Студент"
          contentClassName="tabs=form-footballers tabs=form-footballers1"
        >
          <ChangeMainInfo id={props.id} />
        </FormTab>
        <FormTab label="Статистика">
          <StatIterator />
        </FormTab>
        <FormTab label="Дата Оплаты">
                    <ChangeSecondaryInfo id={props.id} />
                </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default {
  FootballersList,
  FootballersCreate,
  FootballersEdit,
  FootballerShowStats,
};
