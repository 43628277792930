import React, { Component, useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { TextInput, NumberInput, DateInput, ArrayInput, SimpleFormIterator, minValue } from 'react-admin';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { Field } from 'react-final-form';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import axios from 'axios';

import numeral from "numeral";
import { useEffect } from 'react';


var aa;

async function FetcFunc(id) {
    await fetch("https://cdn.lk-ft.ru/footballers/" + id)
        .then((res) => {
            return res.json()
        })
        .then((res) => {
            // console.log(res)
            aa = res.Statistics
            return aa;
        })
}



const StatIterator = ({ record }) => {

    const [Player, setPlayer] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [footballPlayer, setFootballPlayer] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);

    // console.log(record);
    let lastId
    const fetchData = () => {
        fetch('https://cdn.lk-ft.ru/footballers/' + record.id)
            .then((response) => response.json())
            .then((data) => {
                setIsLoading(false);
                setPlayer(data.Statistics);
                setFootballPlayer(data);
                // lastId = Player[Player.length - 1].id;
            })
            .catch((error) => {
                setIsLoading(false);
                setIsError(true);
                console.log(error);
            });
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    // Player ? console.log(Player) : console.log('pppp');
    // lastId = Player ? Player[Player.length - 1].id : 0;
    // console.log(lastId)

    const handleChangeDate = (e, item) => {
        e.preventDefault();
        let res = Player;
        res = res.map((el) => {
            if (el.id == item.id)
                el.date = e.target.value;
            return el;
        })
        setPlayer([...res]);
    }
    const handleChangeHit = (e, item) => {
        e.preventDefault();
        let res = Player;
        res = res.map((el) => {
            if (el.id == item.id)
                el.Hit = e.target.value;
            return el;
        })
        setPlayer([...res]);
    }
    const handleChangeSpeed = (e, item) => {
        e.preventDefault();
        let res = Player;
        res = res.map((el) => {
            if (el.id == item.id)
                el.Speed = Number(e.target.value);
            return el;
        })
        setPlayer([...res]);
    }
    const handleChangeSpeed2 = (e, item) => {
        e.preventDefault();
        let res = Player;
        res = res.map((el) => {
            if (el.id == item.id)
                el.Speed2 = Number(e.target.value);
            return el;
        })
        setPlayer([...res]);
    }
    const handleChangeSpeed_s_razbega = (e, item) => {
        e.preventDefault();
        let res = Player;
        res = res.map((el) => {
            if (el.id == item.id)
                el.Speed_s_razbega = Number(e.target.value);
            return el;
        })
        setPlayer([...res]);
    }
    const handleChangeSpeed_s_razbega2 = (e, item) => {
        e.preventDefault();
        let res = Player;
        res = res.map((el) => {
            if (el.id == item.id)
                el.Speed_s_razbega2 = Number(e.target.value);
            return el;
        })
        setPlayer([...res]);
    }
    const handleChangeReaction = (e, item) => {
        e.preventDefault();
        let res = Player;
        res = res.map((el) => {
            if (el.id == item.id)
                el.Reaction = e.target.value;
            return el;
        })
        setPlayer([...res]);
    }
    const handleChangeJump = (e, item) => {
        e.preventDefault();
        let res = Player;
        res = res.map((el) => {
            if (el.id == item.id)
                el.Jump = e.target.value;
            return el;
        })
        setPlayer([...res]);
    }
    const handleChangeJump2 = (e, item) => {
        e.preventDefault();
        let res = Player;
        res = res.map((el) => {
            if (el.id == item.id)
                el.Jump2 = e.target.value;
            return el;
        })
        setPlayer([...res]);
    }
    const handleChangeSharpshooting = (e, item) => {
        e.preventDefault();
        let res = Player;
        res = res.map((el) => {
            if (el.id == item.id)
                el.sharpshooting = e.target.value;
            return el;
        })
        setPlayer([...res]);
    }
    const handleChangeAgility = (e, item) => {
        e.preventDefault();
        let res = Player;
        res = res.map((el) => {
            if (el.id == item.id)
                el.Agility = e.target.value;
            return el;
        })
        setPlayer([...res]);
    }
    const handleChangeFootSkill = (e, item) => {
        e.preventDefault();
        let res = Player;
        res = res.map((el) => {
            if (el.id == item.id)
                el.FootSkill = e.target.value;
            return el;
        })
        setPlayer([...res]);
    }
    const handleChangeFootSkill2 = (e, item) => {
        e.preventDefault();
        let res = Player;
        res = res.map((el) => {
            if (el.id == item.id)
                el.FootSkill2 = e.target.value;
            return el;
        })
        setPlayer([...res]);
    }
    const handleMessiTestDriblingTest = (e, item) => {
        e.preventDefault();
        let res = Player;
        res = res.map((el) => {
            if (el.id == item.id)
                el.MessiTestDriblingTest = e.target.value;
            return el;
        })
        setPlayer([...res]);
    }
    const handleExstraColorDriblingTest = (e, item) => {
        e.preventDefault();
        let res = Player;
        res = res.map((el) => {
            if (el.id == item.id)
                el.ExstraColorDriblingTest = e.target.value;
            return el;
        })
        setPlayer([...res]);
    }

	const handleMessiTestDriblingTest2 = (e, item) => {
         e.preventDefault();
         let res = Player;
         res = res.map((el) => {
             if (el.id == item.id)
                 el.MessiTestDriblingTest2 = e.target.value;
             return el;
         })
         setPlayer([...res]);
     }
     const handleExstraColorDriblingTest2 = (e, item) => {
         e.preventDefault();
         let res = Player;
         res = res.map((el) => {
             if (el.id == item.id)
                 el.ExstraColorDriblingTest2 = e.target.value;
             return el;
         })
         setPlayer([...res]);
     }
    const addNewStat = () => {
        let emptyStat = {
            "date": new Date(),
            "Speed": 0,
            "Hit": "",
            "Reaction": "",
            "Jump": "",
            "Hitt": null,
            "date_of_game": null,
            "sharpshooting": "",
            "Speed2": 0,
            "Speed_s_razbega": 0,
            "Speed_s_razbega2": 0,
            "Jump2": "",
            "Agility": "",
            "FootSkill": "",
            "FootSkill2": "",
            "ExstraColorDriblingTest": 0,
            "MessiTestDriblingTest": 0,
			"ExstraColorDriblingTest2": 0,
			"MessiTestDriblingTest2": 0,
            "special_number": null
        }
        let res = Player;
        res.push(emptyStat);
        setPlayer([...res]);
        // console.log(Player);
    }

    const deleteItem = (index) => {
        let res = Player;
        res.map((e, i) => {
            if (i == index) {
                res.splice(i, 1);
            }
        })
        setPlayer([...res]);
        // console.log(Player);
    }

    const saveData = () => {
        // console.log(footballPlayer);
        setDisabled(true)
        axios
            .put('https://cdn.lk-ft.ru/footballers/' + record.id, footballPlayer)
            .then((res) => {
                // console.log("res:", res);
                setTimeout(alert('Изменения сохранены'), 800);
                setDisabled(false);
            })
            .catch((error) => {
                console.log("err:", error);
                setDisabled(false);
            })

    }

    useEffect(() => {
        if (footballPlayer && JSON.stringify(footballPlayer) != "{}") {
            setFootballPlayer(s => ({ ...s, Statistics: Player }));
            // console.log(footballPlayer);
        }
    }, [Player])

    if (isLoading) {
        return <div>Загрузка...</div>;
    }

    return (
        // <ArrayInput source='Statistics'>
        //     <SimpleFormIterator>
        //         <DateInput label="Дата" source={`date`} />
        //         <NumberInput label="10 метров с места (км/ч)" source={`Speed`} />
        //     </SimpleFormIterator>
        // </ArrayInput>
        <>
            {
                Player?.map((item, i) => {
                    return (
                        <div key={i} style={{ margin: "50px 0px" }}>
                            {/* <form style={{ margin: "50px 0px" }} onSubmit={handleSubmit}> */}
                            <input
                                style={{ height: "50px", marginTop: "6px", borderRadius: "5px", borderColor: "grey", marginLeft: "10px" }}
                                type='Date'
                                name="Дата"
                                value={item.date}
                                onChange={(e) => { handleChangeDate(e, item) }}
                            />
                            <TextField style={{ margin: "5px 10px" }}
                                variant="outlined"
                                label="Удар"
                                type="number"
                                value={item.Hit}
                                onChange={(e) => { handleChangeHit(e, item) }}
                            />
                            <TextField
                                style={{ margin: "5px 10px" }}
                                variant="outlined"
                                label="10 метров с места"
                                type="number"
                                value={item.Speed}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">км/ч</InputAdornment>,
                                }}
                                onChange={(e) => { handleChangeSpeed(e, item) }}
                            />
                            <TextField
                                style={{ margin: "5px 10px" }}
                                variant="outlined"
                                label="10 метров с места"
                                type="number"
                                value={item.Speed2}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">сек</InputAdornment>,
                                }}
                                onChange={(e) => { handleChangeSpeed2(e, item) }}
                            />
                            <TextField
                                style={{ margin: "5px 10px" }}
                                variant="outlined"
                                label="10 метров с разбега"
                                type="number"
                                value={item.Speed_s_razbega}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">км/ч</InputAdornment>,
                                }}
                                onChange={(e) => { handleChangeSpeed_s_razbega(e, item) }}
                            />
                            <TextField
                                style={{ margin: "5px 10px" }}
                                variant="outlined" l
                                label="10 метров с разбега"
                                type="number"
                                value={item.Speed_s_razbega2}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">сек</InputAdornment>,
                                }}
                                onChange={(e) => { handleChangeSpeed_s_razbega2(e, item) }}
                            />
                            <TextField
                                style={{ margin: "5px 10px" }}
                                variant="outlined"
                                label="Реакция"
                                type="number"
                                value={item.Reaction}
                                onChange={(e) => { handleChangeReaction(e, item) }}
                            />
                            <TextField
                                style={{ margin: "5px 10px" }}
                                variant="outlined"
                                label="Прыжок в высоту"
                                type="number"
                                value={item.Jump}
                                onChange={(e) => { handleChangeJump(e, item) }}
                            />
                            <TextField
                                style={{ margin: "5px 10px" }}
                                variant="outlined"
                                label="Прыжок в длину"
                                type="number"
                                value={item.Jump2}
                                onChange={(e) => { handleChangeJump2(e, item) }}
                            />
                            <TextField
                                style={{ margin: "5px 10px" }}
                                variant="outlined" label="Точность"
                                type="number"
                                value={item.sharpshooting}
                                onChange={(e) => { handleChangeSharpshooting(e, item) }}
                            />
                            <TextField
                                style={{ margin: "5px 10px" }}
                                variant="outlined"
                                label="Agility тест"
                                type="number"
                                value={item.Agility}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">сек</InputAdornment>,
                                }}
                                onChange={(e) => { handleChangeAgility(e, item) }}
                            />
                            <TextField
                                style={{ margin: "5px 10px" }}
                                variant="outlined"
                                label="FootSkill тест"
                                type="number"
                                value={item.FootSkill}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">сек</InputAdornment>,
                                }}
                                onChange={(e) => { handleChangeFootSkill(e, item) }}
                            />
                            <TextField
                                style={{ margin: "5px 10px" }}
                                variant="outlined"
                                label="FootSkill тест"
                                type="number"
                                value={item.FootSkill2}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">удары</InputAdornment>,
                                }}
                                onChange={(e) => { handleChangeFootSkill2(e, item) }}
                            />
                            <TextField
                                style={{ margin: "5px 10px" }}
                                variant="outlined"
                                label="Messi дриблинг тест"
                                type="number"
                                value={item.MessiTestDriblingTest}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">сек</InputAdornment>,
                                }}
                                onChange={(e) => { handleMessiTestDriblingTest(e, item) }}
                            />
                            <TextField
                                style={{ margin: "5px 10px" }}
                                variant="outlined"
                                label="Messi дриблинг тест"
                                type="number"
                                value={item.ExstraColorDriblingTest}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">касание</InputAdornment>,
                                }}
                                onChange={(e) => { handleExstraColorDriblingTest(e, item) }}
                            />
                            <TextField
                                style={{ margin: "5px 10px" }}
                                variant="outlined"
                                label="Messi дриблинг тест2"
                                type="number"
                                value={item.MessiTestDriblingTest2}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">сек</InputAdornment>,
                                }}
                                onChange={(e) => { handleMessiTestDriblingTest2(e, item) }}
                            />
                            <TextField
                                style={{ margin: "5px 10px" }}
                                variant="outlined"
                                label="Messi дриблинг тест2"
                                type="number"
                                value={item.ExstraColorDriblingTest2}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">касание</InputAdornment>,
                                }}
                                onChange={(e) => { handleExstraColorDriblingTest2(e, item) }}
                            />
                            <Button
                                variant="outlined"
                                style={{
                                    color: "red",
                                    borderColor: "red",
                                    marginTop: "13px"
                                }}
                                onClick={() => deleteItem(i)}>
                                Удалить
                            </Button>
                            {/* </form> */}

                        </div>


                    )
                })
            }
            <Button
                type="button"
                variant="outlined"
                color="warning"
                style={{
                    borderColor: "blue",
                    marginRight: "15px",
                    color: "blue"
                }}
                disabled={disabled}
                onClick={saveData}
            >
                Сохранить
            </Button>
            <Button
                type="button"
                variant="outlined"
                style={{
                    borderColor: "black"
                }}
                onClick={addNewStat}
            >
                <Add />
            </Button>
        </>

        // <div>
        //     <TextField value={Player.Statistics?.Speed}/>
        // </div>

        // <FieldArray name="Statistics">
        //     {(fieldProps) => {

        //         console.log(Player)
        //         // fieldProps.fields.value =  FetcFunc(String(record.id))
        //         // console.log(fieldProps)

        //         return (
        //             <>
        //                 <TableContainer>
        //                     <Table aria-label="Statistics List">
        //                         <TableBody>
        //                             {
        //                                 fieldProps.fields.map((s, index) => {

        //                                     return (
        //                                         <>
        //                                             <TableRow hover tabIndex={-1} key={index}>

        //                                                 <TableCell padding='normal' align="justify">
        //                                                     <DateInput label="Дата" source={`Statistics[${index}].date`} />
        //                                                 </TableCell>
        //                                                 <TableCell align="center">
        //                                                     <TextInput label="Удар" source={`Statistics[${index}].Hit`} />
        //                                                 </TableCell>
        //                                                 <TableCell align="left">
        //                                                     <NumberInput label="10 метров с места (км/ч)" source={`Statistics[${index}].Speed`} />
        //                                                 </TableCell>
        //                                                 <TableCell align="left">
        //                                                     <NumberInput label="10 метров с места (сек)" source={`Statistics[${index}].Speed2`} />
        //                                                 </TableCell>
        //                                                 <TableCell align="left">
        //                                                     <NumberInput label="10 метров с разбега (сек)" source={`Statistics[${index}].Speed_s_razbega`} />
        //                                                 </TableCell>
        //                                                 <TableCell align="left">
        //                                                     <NumberInput label="10 метров с разбега (сек)" source={`Statistics[${index}].Speed_s_razbega2`} />
        //                                                 </TableCell>
        //                                                 <TableCell align="left">
        //                                                     <NumberInput label="Реакция" source={`Statistics[${index}].Reaction`} />
        //                                                 </TableCell>
        //                                                 <TableCell align="left">
        //                                                     <NumberInput label="Прыжок в высоту" source={`Statistics[${index}].Jump`} />
        //                                                 </TableCell>
        //                                                 <TableCell align="left">
        //                                                     <NumberInput label="Прыжок в длинну" source={`Statistics[${index}].Jump2`} />
        //                                                 </TableCell>
        //                                                 <TableCell align="left">
        //                                                     <NumberInput label="Точность" source={`Statistics[${index}].sharpshooting`} />
        //                                                 </TableCell>
        //                                                 <TableCell align="left">
        //                                                     <NumberInput label="Agility test (сек)" source={`Statistics[${index}].Agility`} />
        //                                                 </TableCell>
        //                                                 <TableCell align="left">
        //                                                     <NumberInput label="FootSkill test (cек)" source={`Statistics[${index}].FootSkill`} />
        //                                                 </TableCell>
        //                                                 <TableCell align="left">
        //                                                     <NumberInput label="FootSkill test (ударов)" source={`Statistics[${index}].FootSkill2`} />
        //                                                 </TableCell>
        //                                                 <TableCell align="left">
        //                                                     <Button style={{ color: 'red' }} type="button" onClick={() => fieldProps.fields.remove(index)}>
        //                                                         Удалить
        //                                                     </Button>
        //                                                 </TableCell>
        //                                             </TableRow>

        //                                         </>
        //                                     );
        //                                 }

        //                                 )}
        //                         </TableBody>
        //                     </Table>
        //                     <Button
        //                         type="button"
        //                         onClick={() => fieldProps.fields.push({
        //                             date: '',
        //                             date_of_game: null,
        //                             Hit: "",
        //                             Hitt: null,
        //                             Speed: "",
        //                             Speed2: "",
        //                             Speed_s_razbega: "",
        //                             Speed_s_razbega2: "",
        //                             Reaction: "",
        //                             Jump: "",
        //                             Jump2: "",
        //                             sharpshooting: "",
        //                             Agility: "",
        //                             FootSkill: "",
        //                             FootSkill2: "",
        //                             special_number: null
        //                         })}
        //                         color="secondary"
        //                         variant="contained"
        //                         style={{ marginTop: '16px' }}
        //                     >
        //                         <Add />
        //                     </Button>
        //                 </TableContainer>
        //             </>
        //         )
        //     }
        //     }
        // </FieldArray>
    )
}
//iterotor for edit stats


export class StatArrayTwo extends Component {
    constructor(props) {
        super(props);
        const { source } = props;
    }

    renderList = ({ fields }) => {
        const { children } = this.props;
        return (
            <div style={{ margin: '1em' }}>
                {fields.map((member, index) =>
                    <div key={index} style={{ display: 'inline-block', verticalAlign: 'bottom' }}>
                        <div style={{ padding: '0 1em 1em 1em' }}>
                            {React.Children.map(children, input => input && (
                                <div key={input.props.source} className={`aor-input-${input.props.source}`} style={input.props.style}>
                                    <Field {...input.props} name={`${member}.${input.props.source}`} component={input.type} />
                                </div>
                            ))}
                        </div>
                        <Button style={{ color: 'red' }} type="button" onClick={() => fields.remove(index)}>
                            Удалить
                        </Button>
                    </div>
                )}
                <Button type="button" color="secondary" style={{ marginTop: '16px' }} variant="contained" label="Add" onClick={() => fields.push({})} >
                    <Add />
                </Button>
            </div>
        )
    }


    render() {
        const { input, resource, label, source, allowEmpty, basePath, onChange, children, meta } = this.props;

        return (
            <FieldArray name={source} component={this.renderList} />
        )
    }
}



/*
StatArrayTwo.propTypes = {
    addField: PropTypes.bool.isRequired,
    allowEmpty: PropTypes.bool.isRequired,
    basePath: PropTypes.string,
    children: PropTypes.node,
    label: PropTypes.string,
    meta: PropTypes.object,
    onChange: PropTypes.func,
    input: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
};

StatArrayTwo.defaultProps = {
    addField: false,
    allowEmpty: false,
};
*/




export default StatIterator;


