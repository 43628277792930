import { useRecordContext } from 'react-admin';
import * as React from "react";
// import { Link } from 'react-router-dom';
import { 
   Typography
} from "@material-ui/core";
import * as getAge from "get-age";
import { ImageField } from 'react-admin';
import { PlayerCard } from '../components';

const style = {
   borderRadius: 40
}

//import PropTypes from "react-admin";


export const Avatar = (props) => {
    const field = useRecordContext(props);
    // const linkToUser = linkToRecord('/users', post.user_id, 'show');
    //  console.log(field);
    const {medium, small, thumbnail} = field?.avatar[0].formats;
    // <PlayerCard img = {imag}/>
    return <img width={70} style={style} src={'https://cdn.lk-ft.ru/'+small.url} alt={'avatar'}/>;
};


export const PlayerAvatar = (props) => {
    const field = useRecordContext(props);
    // const linkToUser = linkToRecord('/users', post.user_id, 'show');
    //  console.log(field);
   // const {medium, small, thumbnail} = field?.avatar[0];
    // <PlayerCard img = {imag}/>
    if(field?.avatar == null){
        if(field?.Post_image == null)
            return <img width={70} style={style} src={'https://cdn.lk-ft.ru/uploads/small_png_transparent_cartoon_drawing_whatsapp_boys_comics_face_head_653814e7e6.png?6459691.3'} alt={'avatar'}/>;
        else{
            let image=field?.Post_image;
            return <img width={70} style={style} src={'https://cdn.lk-ft.ru'+image.url} alt={'Post Image'}/>;
        }
    }else {
        let image=field?.avatar;
         return <img width={70} style={style} src={'https://cdn.lk-ft.ru'+image.url} alt={'avatar'}/>;
    }
   // return <img width={70} style={style} src={'https://cdn.lk-ft.ru'+image} alt={'avatar'}/>;
};

export const PrewAvatar = ({record , source}) => {
   console.log("record from image input:",record);
   if( typeof(record) =="string"){
       record={
           [source]:record
       }
   }
   return <ImageField record={record} source={source} />
};



/*PlayerAvatar.propTypes = {
    label: PropTypes.node,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};*/


export const Age = (props) => {
    const field = useRecordContext(props);
    // const linkToUser = linkToRecord('/users', post.user_id, 'show');
    //  console.log("age field",field.age);
    

    return <kbd  style={{fontSize:'1em', color:'red', fontWeight:'bolder'}} >
        {field.age ? getAge(field.age)+" yrs" : field.age}
        </kbd>  
};

export const Age_Card= (props) => {
    const field = useRecordContext(props);
    // const linkToUser = linkToRecord('/users', post.user_id, 'show');
    //  console.log("age field",field.age);
    

    return <PlayerCard age={field.age} atk={getAge(field.age)}/>;
};
export default { Avatar, Age , PlayerAvatar, PrewAvatar};