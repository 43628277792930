import Cookies from './helpers/Cookies'
import simpleRestProvider from 'ra-strapi-rest';
// import { PostList } from './posts';
import { fetchUtils } from 'react-admin';



const  uploadFields = [ "avatar" , "Post_image"];


export const authProvider = {

    login: ({ username, password }) => {
        const identifier = username // strapi expects 'identifier' and not 'username'
        const request = new Request('https://cdn.lk-ft.ru/auth/local', {
            method: 'POST',
            body: JSON.stringify({ identifier, password }),
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                /* eslint-disable no-restricted-globals */
                location.reload()
                /* eslint-enable no-restricted-globals */
                return response.json();
            })
            .then(response => {
                Cookies.setCookie('token', response.jwt, 1);
                sessionStorage.setItem("auth", JSON.stringify({ id:response.user?.id, username:response.user?.username, avatar:"https://cdn.lk-ft.ru"+response.user?.avatar.url }));
                Cookies.setCookie('role', response.user.role.name, 1);
                
            });
    },

    logout: () => {
        Cookies.deleteCookie('token');
        Cookies.deleteCookie('role');
        sessionStorage.removeItem("auth");
        return Promise.resolve();
    },

    checkAuth: () => {
        return Cookies.getCookie('token') ? Promise.resolve() : Promise.reject();
    },

    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            Cookies.deleteCookie('token');
            Cookies.deleteCookie('role');
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {
        const role = Cookies.getCookie('role');
        return role ? Promise.resolve(role) : Promise.reject();
    },
    getIdentity: () => {
        try {
            const { id, username, avatar } = JSON.parse(sessionStorage.getItem('auth'));
            return Promise.resolve({ id, fullName:username, avatar });
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = Cookies.getCookie('token')
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}


export const dataProvider = simpleRestProvider('https://cdn.lk-ft.ru', httpClient, uploadFields )  ;
export default {dataProvider:dataProvider, authProvider:authProvider};