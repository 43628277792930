import * as React from 'react';
import { createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, Button } from 'react-admin';
import { withRouter } from 'react-router-dom';
import DashboardOutlined from '@material-ui/icons/DashboardOutlined';
import { useState } from 'react';
import * as XLSX from 'xlsx';

const ExcelToBase64 = () => {
  const [base64, setBase64] = useState('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Преобразование книги в строку base64
      const base64String = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });
      setBase64(base64String);
    };

    reader.readAsArrayBuffer(file);
  };
  const sendFile = async() => {
    const response = await fetch('https://admin.lk-ft.ru/footskill/api/v1/xlsx/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ file: base64 }),
      });
  
      const result = await response.json();
      alert("Файл отправлен")
      console.log(result);
  }
  return (
    <div style={{
        marginLeft: "8%",
        fontSize: "15px",
        marginTop: "20px"
    }}>
      <div>Загрузка xlsx файла</div>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
      {base64 && (
        <button onClick={sendFile}> Отправить файл </button> 
      )}
    </div>
  );
};


export const Menu = ({ onMenuClick, logout }) => {
    
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    return (
        <div style={{margin:"2em 0em 0em 0em"}}>
           <MenuItemLink
                to="/"
                primaryText="Главная"
                leftIcon={<DashboardOutlined />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            
             {resources.map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={resource.options && resource.options.label || resource.name}
                    leftIcon={createElement(resource.icon)}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}
            {/* <button
                style={{
                    marginLeft: "17%",
                    fontSize: "15px"
                }}
            > Загрузить XLSX </button> */}
            <ExcelToBase64/>
            {isXSmall && logout}
        </div>
    );
}

export default withRouter(Menu);