import {
    BooleanInput,
    Filter,
    TextInput
 } from "react-admin";
export const PlayersFilter = (props) => (
    <Filter {...props}>
        <TextInput label="player name" source="fullname" defaultValue="null" />
        <BooleanInput label="is_active" source="active" defaultValue="null" />
    </Filter>
);

export default { PlayersFilter };