import React from 'react';
import { List, Datagrid, TextField,
Show, 
TextInput,
TabbedForm,
FormTab,
Edit,
SimpleShowLayout,
Create,
DateField
} from 'react-admin';
import { ListActions, PostBulkActionButtons, ShowShedule , EditShedule  } from "../custom/actions";


export const SheduleList = (props) =>{
    console.log(props);
    return (
        <List {...props} actions={<ListActions />}   bulkActionButtons={<PostBulkActionButtons />} >       
            <Datagrid   expand={<SheduleShow />}  >
                    <DateField label="Дата тренерки" />
                    <TextField label="Локация"/>
                    <TextField label="Тематика"/>
                
                    <TextField label="Время Суток" source="name" />
                    <EditShedule />
                    <ShowShedule />
            </Datagrid>
        </List>
    );
}

export const SheduleCreate = (props) =>(
    <Create {...props}>
    <TabbedForm contentClassName="tabs-form-shedule">
         <FormTab label="Расписание" contentClassName="tabs-form-shedule">
            <TextInput label="Время Суток" source="name" />
            <TextInput label="Понедельник" source="MondayStart"/>
            <TextInput label="Понедельник Тематика" source="MondayArtema"/>
            <TextInput label="Вторник" source="TuesdayStart"/>
            <TextInput label="Вторник Тематика" source="TuesdayArtema"/>
            <TextInput label="Среда" source="WednesdayStart"/>
            <TextInput label="Среда Тематика" source="WednesdayArtema"/>
            <TextInput label="Четверг" source="ThursdayStart"/>
            <TextInput label="Четверг Тематика" source="ThursdayArtema"/>
            <TextInput label="Пятница" source="FridayStart"/>
            <TextInput label="Пятница Тематика" source="FridayArtema"/>
            <TextInput label="Суббота" source="SaturdayStart"/>
            <TextInput label="Суббота Тематика" source="SaturdayArtema"/>
            <TextInput label="Воскресенье" source="SundayStart"/>
            <TextInput label="Воскресенье Тематика" source="SundayArtema"/>
            <TextInput label="Тренер" source="Triner"/>
            <TextInput label="Локация" source="Loation"/>
         </FormTab>
    </TabbedForm>
</Create>
);

export const SheduleEdit = (props) =>(
    <Edit {...props}>
    <TabbedForm contentClassName="tabs-form-footballers">
         <FormTab label="Расписание" contentClassName="tabs-form-footballers tabs-form-shedule">
            <TextInput label="Время Суток" source="name" />
            <TextInput label="Понедельник" source="MondayStart"/>
            <TextInput label="Понедельник Тематика" source="MondayArtema"/>
            <TextInput label="Вторник" source="TuesdayStart"/>
            <TextInput label="Вторник Тематика" source="TuesdayArtema"/>
            <TextInput label="Среда" source="WednesdayStart"/>
            <TextInput label="Среда Тематика" source="WednesdayArtema"/>
            <TextInput label="Четверг" source="ThursdayStart"/>
            <TextInput label="Четверг Тематика" source="ThursdayArtema"/>
            <TextInput label="Пятница" source="FridayStart"/>
            <TextInput label="Пятница Тематика" source="FridayArtema"/>
            <TextInput label="Суббота" source="SaturdayStart"/>
            <TextInput label="Суббота Тематика" source="SaturdayArtema"/>
            <TextInput label="Воскресенье" source="SundayStart"/>
            <TextInput label="Воскресенье Тематика" source="SundayArtema"/>
            <TextInput label="Трейнер" source="Triner"/>
            <TextInput label="Локация" source="Loation"/>
         </FormTab>
    </TabbedForm>
</Edit>
);

export const SheduleShow =(props)=>(
    <Show {...props} title="Расписание">       
        <SimpleShowLayout className="container-show">
            <TextField label="Время Суток" source="name" />
            <TextField label="Понедельник" source="MondayStart"/>
            <TextField label="Вторник" source="TuesdayStart"/>
            <TextField label="Среда" source="WednesdayStart"/>
            <TextField label="Четверг" source="ThursdayStart"/>
            <TextField label="Пятница" source="FridayStart"/>
            <TextField label="Суббота" source="SaturdayStart"/>
            <TextField label="Воскресенье" source="SundayStart"/>
        </SimpleShowLayout>
    </Show>

);



export default {SheduleList, SheduleCreate, SheduleEdit, SheduleShow};