export const BurgerIcon = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Content / bars">
        <path
          id="Vector"
          d="M3 8H21C21.2652 8 21.5196 7.89464 21.7071 7.70711C21.8946 7.51957 22 7.26522 22 7C22 6.73478 21.8946 6.48043 21.7071 6.29289C21.5196 6.10536 21.2652 6 21 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7C2 7.26522 2.10536 7.51957 2.29289 7.70711C2.48043 7.89464 2.73478 8 3 8ZM21 16H3C2.73478 16 2.48043 16.1054 2.29289 16.2929C2.10536 16.4804 2 16.7348 2 17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H21C21.2652 18 21.5196 17.8946 21.7071 17.7071C21.8946 17.5196 22 17.2652 22 17C22 16.7348 21.8946 16.4804 21.7071 16.2929C21.5196 16.1054 21.2652 16 21 16ZM21 11H3C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8946 2.73478 13 3 13H21C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11Z"
          fill="white"
        />
      </g>
    </svg>
  );
  
  export const ThemeIcon = () => (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5868 11.6C20.5668 6.91 16.7668 4 12.6668 4C8.56681 4 4.76681 6.91 2.74681 11.6C2.69175 11.7262 2.66333 11.8623 2.66333 12C2.66333 12.1377 2.69175 12.2738 2.74681 12.4C4.76681 17.09 8.56681 20 12.6668 20C16.7668 20 20.5668 17.09 22.5868 12.4C22.6419 12.2738 22.6703 12.1377 22.6703 12C22.6703 11.8623 22.6419 11.7262 22.5868 11.6ZM12.6668 18C9.49681 18 6.49681 15.71 4.76681 12C6.49681 8.29 9.49681 6 12.6668 6C15.8368 6 18.8368 8.29 20.5668 12C18.8368 15.71 15.8368 18 12.6668 18ZM12.6668 8C11.8757 8 11.1023 8.2346 10.4445 8.67412C9.78674 9.11365 9.27405 9.73836 8.9713 10.4693C8.66855 11.2002 8.58933 12.0044 8.74367 12.7804C8.89801 13.5563 9.27898 14.269 9.83839 14.8284C10.3978 15.3878 11.1105 15.7688 11.8865 15.9231C12.6624 16.0775 13.4666 15.9983 14.1975 15.6955C14.9285 15.3928 15.5532 14.8801 15.9927 14.2223C16.4322 13.5645 16.6668 12.7911 16.6668 12C16.6668 10.9391 16.2454 9.92172 15.4952 9.17157C14.7451 8.42143 13.7277 8 12.6668 8ZM12.6668 14C12.2713 14 11.8846 13.8827 11.5557 13.6629C11.2268 13.4432 10.9704 13.1308 10.8191 12.7654C10.6677 12.3999 10.6281 11.9978 10.7052 11.6098C10.7824 11.2219 10.9729 10.8655 11.2526 10.5858C11.5323 10.3061 11.8887 10.1156 12.2766 10.0384C12.6646 9.96126 13.0667 10.0009 13.4322 10.1522C13.7976 10.3036 14.11 10.56 14.3298 10.8889C14.5495 11.2178 14.6668 11.6044 14.6668 12C14.6668 12.5304 14.4561 13.0391 14.081 13.4142C13.706 13.7893 13.1972 14 12.6668 14Z"
        fill="#60A3BC"
      />
    </svg>
  );
  export const VisitingIcon = () => (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Vector"
          d="M19.4599 7.2101C19.367 7.11638 19.2564 7.04198 19.1345 6.99121C19.0127 6.94044 18.882 6.91431 18.7499 6.91431C18.6179 6.91431 18.4872 6.94044 18.3654 6.99121C18.2435 7.04198 18.1329 7.11638 18.04 7.2101L10.59 14.6701L7.45995 11.5301C7.36343 11.4369 7.24949 11.3636 7.12463 11.3143C6.99978 11.2651 6.86645 11.241 6.73227 11.2433C6.59809 11.2457 6.46568 11.2744 6.3426 11.3279C6.21953 11.3814 6.10819 11.4586 6.01495 11.5551C5.92171 11.6516 5.8484 11.7656 5.79919 11.8904C5.74999 12.0153 5.72586 12.1486 5.72818 12.2828C5.7305 12.417 5.75923 12.5494 5.81272 12.6725C5.86622 12.7955 5.94343 12.9069 6.03995 13.0001L9.87995 16.8401C9.97291 16.9338 10.0835 17.0082 10.2054 17.059C10.3272 17.1098 10.4579 17.1359 10.59 17.1359C10.722 17.1359 10.8527 17.1098 10.9745 17.059C11.0964 17.0082 11.207 16.9338 11.3 16.8401L19.4599 8.68011C19.5615 8.58646 19.6425 8.47281 19.6979 8.34631C19.7533 8.21981 19.7819 8.08321 19.7819 7.94511C19.7819 7.807 19.7533 7.6704 19.6979 7.5439C19.6425 7.4174 19.5615 7.30375 19.4599 7.2101Z"
          fill="#4AB538"
        />
      </g>
    </svg>
  );
  export const NoVisitingIcon = () => (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Vector"
          d="M14.1599 11.9999L18.4599 7.70994C18.6482 7.52164 18.754 7.26624 18.754 6.99994C18.754 6.73364 18.6482 6.47825 18.4599 6.28994C18.2716 6.10164 18.0162 5.99585 17.7499 5.99585C17.4836 5.99585 17.2282 6.10164 17.0399 6.28994L12.7499 10.5899L8.45994 6.28994C8.27164 6.10164 8.01624 5.99585 7.74994 5.99585C7.48364 5.99585 7.22824 6.10164 7.03994 6.28994C6.85164 6.47825 6.74585 6.73364 6.74585 6.99994C6.74585 7.26624 6.85164 7.52164 7.03994 7.70994L11.3399 11.9999L7.03994 16.2899C6.94621 16.3829 6.87182 16.4935 6.82105 16.6154C6.77028 16.7372 6.74414 16.8679 6.74414 16.9999C6.74414 17.132 6.77028 17.2627 6.82105 17.3845C6.87182 17.5064 6.94621 17.617 7.03994 17.7099C7.1329 17.8037 7.2435 17.8781 7.36536 17.9288C7.48722 17.9796 7.61793 18.0057 7.74994 18.0057C7.88195 18.0057 8.01266 17.9796 8.13452 17.9288C8.25638 17.8781 8.36698 17.8037 8.45994 17.7099L12.7499 13.4099L17.0399 17.7099C17.1329 17.8037 17.2435 17.8781 17.3654 17.9288C17.4872 17.9796 17.6179 18.0057 17.7499 18.0057C17.882 18.0057 18.0127 17.9796 18.1345 17.9288C18.2564 17.8781 18.367 17.8037 18.4599 17.7099C18.5537 17.617 18.6281 17.5064 18.6788 17.3845C18.7296 17.2627 18.7557 17.132 18.7557 16.9999C18.7557 16.8679 18.7296 16.7372 18.6788 16.6154C18.6281 16.4935 18.5537 16.3829 18.4599 16.2899L14.1599 11.9999Z"
          fill="#B53838"
        />
      </g>
    </svg>
  );
  export const MoreInfoIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16V12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11ZM12.38 7.08C12.1365 6.97998 11.8635 6.97998 11.62 7.08C11.4973 7.12759 11.3851 7.19896 11.29 7.29C11.2017 7.3872 11.1306 7.49882 11.08 7.62C11.024 7.73868 10.9966 7.86882 11 8C10.9992 8.13161 11.0245 8.26207 11.0742 8.38391C11.124 8.50574 11.1973 8.61656 11.29 8.71C11.3872 8.79833 11.4988 8.86936 11.62 8.92C11.7715 8.98224 11.936 9.00632 12.099 8.99011C12.2619 8.97391 12.4184 8.91792 12.5547 8.82707C12.691 8.73622 12.8029 8.61328 12.8805 8.46907C12.9582 8.32486 12.9992 8.16378 13 8C12.9963 7.73523 12.8927 7.48163 12.71 7.29C12.6149 7.19896 12.5028 7.12759 12.38 7.08ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
        fill="#595959"
      />
    </svg>
  );
  export const NoStatsIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19 11H13V5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H11V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z"
        fill="#CCCCCC"
      />
    </svg>
  );
  export const StatsIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19 11H13V5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H11V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z"
        fill="#595959"
      />
    </svg>
  );
  export const CloseModalIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 13.4142L19.7782 21.1924L21.1924 19.7782L13.4142 12L21.1924 4.22183L19.7782 2.80762L12 10.5858L4.22183 2.80762L2.80762 4.22183L10.5858 12L2.80762 19.7782L4.22183 21.1924L12 13.4142Z"
        fill="#CCCCCC"
      />
    </svg>
  );